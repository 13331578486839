import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Accordion from "./../../components/common/products/accordion";
import ProductNav from "../../components/productNav";
import whiteBg from "./whiteBackground.jpg";
import HighPerformanceDetection from "./high_performance_detection.png";
import Layout from "../../components/layout/layout";
import ProductFeatures from "../../components/common/products/productFeatures";
import ProductFeatures2 from "../../components/common/products/productFeatures2";
import ProductHeadline from "../../components/common/products/productHeadline";
import ProductHeading from "../../components/common/products/productHeading";
import formatTitle from "../../components/common/formatTitle";
import HeadingWithBg from "../../components/common/products/headingProductWithBg";
import Illustration from "./illustration_2.png";
import Dangerous from "./detection_dangerous.jpeg";
import ImageComparisonSlider from "../../components/comparisonSlider/imageComparisonSlider";
import PhotoAccordion from "./photo_accordion.jpg";
import Illustration1 from "./illustration_1.jpg";
import Illustration2 from "./illustration_2.jpg";
import Illustration3 from "./illustration_3.jpg";
import WithBoxes from "./with_boxes.jpg";
import WithoutBoxes from "./withtout_boxes.jpg";
import TunnelwatchDashboard from "./tunnelwatchDashboard.png";
import Features14 from "./features14.svg";
import Features13 from "./features13.svg";
import Features12 from "./features12.svg";
import Features11 from "./features11.svg";
import Features24 from "./features24.svg";
import Features23 from "./features23.svg";
import Features22 from "./features22.svg";
import Features21 from "./features21.svg";

const useStyles = makeStyles((theme) => ({
  row1: {
    padding: theme.layout.paddingRegular,
    backgroundColor: theme.palette.background.default,
  },
  noPaddingBottom: {
    paddingBottom: 30,
  },
  row1Mobile: {
    padding: theme.layout.paddingRegularMobile,
    backgroundColor: theme.palette.background.default,
  },
  withBg: {
    backgroundColor: theme.palette.background.medium,
  },
  products: { padding: theme.layout.paddingRegular },
  productsMobile: { padding: theme.layout.paddingRegularMobile },
  button: {
    backgroundColor: theme.palette.primary.light,
    color: "#FFF",
    marginTop: "2em",
  },
  target1Btn: {
    backgroundColor: "#FFF",
    color: theme.palette.primary.light,
    padding: "1em",
    minWidth: "150px",
  },
  centered: {
    textAlign: "center",
  },
  leftAligned: {
    textAlign: "left",
  },
  wBg: {
    backgroundImage: `url(${whiteBg})`,
    backgroundSize: "auto 100px",
    backgroundRepeat: "repeat-x",
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  imgRnded: {
    borderRadius: 10,
  },
  dashboard: {
    boxShadow: "6px 15px 20px 8px #e8e7e7",
  },
  morePadded: {
    paddingBottom: "10%",
  },
  illustration: {
    marginBottom: theme.spacing(4),
  },
}));

const docNames = [
  "EN_Tunnelwatch x VA Tunnel de Toulon",
  "EN_Tunnelwatch x DIRIF",
  "EN_Tunnelwatch x Transurban",
];

export default function Tunnelwatch({ data, pageContext: { slug, langKey } }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const datasParsed = [
    "accordion",
    "features1",
    "features2",
    "features3",
    "punchlines",
  ].reduce(
    (m, k) => ({
      ...m,
      [k]: data.allTunnelwatchJson.nodes.find((n) => n[k] !== null)[k][langKey],
    }),
    {}
  );

  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="stretch">
          <HeadingWithBg
            title={datasParsed["punchlines"][0]["title"]}
            content={datasParsed["punchlines"][0]["content"]}
            imgAlt={datasParsed["punchlines"][0]["alt"]}
            src={TunnelwatchDashboard}
            langKey={langKey}
            uri={`/${langKey}/contact?object=demo_tunnelwatch`}
          />
          <ProductFeatures
            features={datasParsed["features1"]}
            iconOnSameRow={false}
            icons={[
              { img: Features11, alt: "illustration1" },
              { img: Features12, alt: "illustration1" },
              { img: Features13, alt: "illustration1" },
              { img: Features14, alt: "illustration1" },
            ]}
          />
          <ProductHeadline
            title={datasParsed["punchlines"][1]["title"]}
            content={datasParsed["punchlines"][1]["content"]}
            more={datasParsed["punchlines"][1]["more"]}
            buttonParams={{ label: "Get in touch", uri: `/${langKey}/contact` }}
          />
          <Grid
            item
            className={clsx(classes.centered, {
              [classes.noPaddingBottom]: matches,
              [classes.row1]: matches,
              [classes.withBg]: matches,
              [classes.row1Mobile]: !matches,
            })}
            container
            direction="column"
            spacing={5}
          >
            <Grid item className={classes.illustration}>
              <img
                src={Illustration}
                alt="roadshare"
                width={"100%"}
                height="auto"
                className={classes.dashboard}
              />
            </Grid>
            <ProductFeatures
              features={datasParsed["features2"]}
              iconOnSameRow={!matches}
              icons={[
                { img: Features21, alt: "illustration1" },
                { img: Features22, alt: "illustration1" },
                { img: Features23, alt: "illustration1" },
                { img: Features24, alt: "illustration1" },
              ]}
              noPadding
              withBg
            />
          </Grid>
          <Grid
            item
            className={clsx({
              [classes.row1]: matches,
              [classes.row1Mobile]: !matches,
            })}
          >
            <Grid container direction="row" justify="space-between" spacing={4}>
              <Grid item sm={6} xs={12}>
                <img
                  src={PhotoAccordion}
                  width="100%"
                  height="auto"
                  alt="roadshare"
                  className={classes.imgRnded}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Accordion expandedDark items={datasParsed["accordion"]} />
              </Grid>
            </Grid>
          </Grid>

          {matches && (
            <Grid item className={clsx(classes.morePadded, classes.row1)}>
              <Grid container direction="column" spacing={4}>
                <Grid item xs={4} md={12} className={classes.centered}>
                  <Typography variant="h1">
                    Visual <span className={classes.enLight}>demo</span>
                  </Typography>
                </Grid>
                <Grid item className={classes.centered}>
                  <div style={{ width: 1024, height: 329, margin: "auto" }}>
                    <ImageComparisonSlider
                      image1={WithBoxes}
                      image2={WithoutBoxes}
                      sliderColor={theme.palette.primary.light}
                      sliderWidth={5}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          <ProductHeading
            title={formatTitle(datasParsed["punchlines"][2]["title"])}
            content={datasParsed["punchlines"][2]["content"]}
            imgAlt={datasParsed["punchlines"][2]["alt"]}
            src={HighPerformanceDetection}
            imageSizeOnMobile={12}
            noButtonOnMobile
            illustrationInverted
            tag="High performance"
            buttonParams={{ uri: `/${langKey}/skills`, label: "Know more" }}
          />
          <ProductHeading
            title={formatTitle(datasParsed["punchlines"][3]["title"])}
            content={datasParsed["punchlines"][3]["content"]}
            imgAlt={datasParsed["punchlines"][3]["alt"]}
            src={Dangerous}
            imageSizeOnMobile={12}
            reverse
            noButtonOnMobile
            tag="New feature"
            buttonParams={{ uri: `/${langKey}/contact`, label: "Contact us" }}
            lg={6}
          />
          <ProductFeatures2
            langKey={langKey}
            features={[Illustration2, Illustration1, Illustration3].map(
              (image, idx) => ({
                image,
                ...datasParsed["features3"][idx],
                docName: docNames[idx],
              })
            )}
          />
          <Grid
            item
            className={clsx({
              [classes.products]: matches,
              [classes.productsMobile]: !matches,
            })}
            xs={12}
            container
            direction="column"
            spacing={matches ? 4 : 0}
            alignItems="stretch"
          >
            <Grid item container direction="row" justify="center">
              <Grid
                item
                xs={12}
                className={clsx({
                  [classes.centered]: matches,
                  [classes.leftAligned]: !matches,
                })}
              >
                <Typography variant="h1">
                  Discover our <span className={classes.enLight}>products</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" justify="center" spacing={2}>
              <Grid item xs={10}>
                <ProductNav
                  langKey={langKey}
                  toHide={[slug.split("/")[2].toLowerCase()]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  query TunnelwatchQueryEn {
    allTunnelwatchJson {
      nodes {
        accordion {
          en {
            content
            title
          }
        }
        punchlines {
          en {
            content
            title
            more
            alt
          }
        }
        features3 {
          en {
            content
            title
            alt
          }
        }
        features2 {
          en {
            content
            title
          }
        }
        features1 {
          en {
            content
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
